import { Cancel, Done, School } from "@mui/icons-material";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  Container,
  Drawer,
  Typography,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  List,
} from "@mui/material";
import { useFetch } from "hooks";
import { database } from "configs";
import { useState, useEffect } from "react";

const AssignUniversityDrawer = ({
  mainId,
  open,
  setOpenAssignDriverDrawer,
  setRealtime,
}) => {
  const [data, loading] = useFetch(`/Users`);
  const [universities, setUniversities] = useState([]);

  useEffect(() => {
    if (open?.id) {
      const fetchUniversities = async () => {
        try {
          const snapshot = await database.ref(`Countries/${mainId}/cities/${open?.id}`).once('value');
          const updatedUniversities = snapshot.val()?.universities || [];
          setUniversities(updatedUniversities);
        } catch (error) {
          console.log(error);
        }
      };
      fetchUniversities();
    }
  }, [open, mainId]);

  const handleAssign = async (universityId) => {
    try {
      const updatedUniversities = [...new Set([universityId, ...universities])];

      await database.ref(`Countries/${mainId}`).update({
        universities: updatedUniversities,
      });

      await database.ref(`Countries/${mainId}/cities/${open?.id}`).update({
        universities: updatedUniversities,
      });

      const userSnap = await database.ref(`Users/${universityId}`).once("value");
      const existingCountries = userSnap.val()?.countries || [];
      const existingCities = userSnap.val()?.cities || [];

      const updatedCountries = [...new Set([mainId, ...existingCountries])];
      const updatedCities = [...new Set([open?.id, ...existingCities])];

      await Promise.all([
        database.ref(`Users/${universityId}`).update({
          countries: updatedCountries,
          cities: updatedCities,
        }),
      ]);

      setUniversities(updatedUniversities);

    } catch (error) {
      console.log(error);
    }
  };

  const handleRemove = async (universityId) => {
    try {
      const updatedUniversities = universities.filter(id => id !== universityId);

      await database.ref(`Countries/${mainId}`).update({
        universities: updatedUniversities,
      });

      await database.ref(`Countries/${mainId}/cities/${open?.id}`).update({
        universities: updatedUniversities,
      });

      const userSnap = await database.ref(`Users/${universityId}`).once("value");
      const existingCountries = userSnap.val()?.countries || [];
      const existingCities = userSnap.val()?.cities || [];

      const updatedCountries = existingCountries.filter(countryId => countryId !== mainId);
      const updatedCities = existingCities.filter(cityId => cityId !== open?.id);

      await Promise.all([
        database.ref(`Users/${universityId}`).update({
          countries: updatedCountries,
          cities: updatedCities,
        }),
      ]);

      setUniversities(updatedUniversities);

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={Boolean(open?.id)}
      onClose={() => setOpenAssignDriverDrawer(false)}
    >
      <Container
        style={{
          width: "30vw",
          marginTop: "12vh",
        }}
      >
        <Typography
          align="left"
          color="Highlight"
          sx={{ fontWeight: "bold", marginLeft: "1.8vw" }}
          variant="h6"
        >
          Assign Universities
        </Typography>
        <List>
          {data
            ?.filter((user) => user?.role === "university")
            ?.map((university) => (
              <ListItem key={university?.id} sx={{ marginTop: "" }}>
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: "#1877f2" }}>
                    <School className="!text-2xl" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={university?.displayName}
                  secondary={university?.email}
                  primaryTypographyProps={{
                    fontWeight: "bold",
                    fontSize: "1vw",
                    color: "#1877f2",
                  }}
                  secondaryTypographyProps={{
                    fontSize: "1vw",
                  }}
                />
                {universities.includes(university?.id) ? (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="remove"
                      onClick={() => handleRemove(university?.id)}
                    >
                      <Cancel color="secondary" />
                    </IconButton>
                  </ListItemSecondaryAction>
                ) : (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="assign"
                      onClick={() => handleAssign(university?.id)}
                    >
                      <Done color="success" />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
        </List>
      </Container>
    </Drawer>
  );
};

export default AssignUniversityDrawer;
