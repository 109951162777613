import React, { useEffect, useState } from 'react';
import MaterialTable from '@material-table/core';
import { CircularProgress } from '@mui/material';
import firebase from 'firebase/app';
import 'firebase/database';
import { ExportCsv } from "@material-table/exporters"; 

const ViewRegisterStudentData = () => {
  const [registeredStudents, setRegisteredStudents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStudents = async () => {
      const studentsRef = firebase.database().ref('NewFairs');
      studentsRef.on('value', (snapshot) => {
        const fairsData = snapshot.val();
        if (fairsData) {
          const allStudents = [];
          Object.keys(fairsData).forEach(fairId => {
            const students = fairsData[fairId].students;
            const schoolName = fairsData[fairId].displayName; 
            if (students) {
              Object.keys(students).forEach(studentID => {
                allStudents.push({
                  studentID: studentID,
                  ...students[studentID],
                  schoolName: schoolName, 
                });
              });
            }
          });
          setRegisteredStudents(allStudents);
        } else {
          setRegisteredStudents([]);
        }
        setLoading(false);
      });

      return () => studentsRef.off('value');
    };

    fetchStudents();
  }, []);

  return (
    <div style={{ maxWidth: '100%' }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <MaterialTable
          title="Registered Students"
          columns={[
            { title: '#', render: rowData => rowData.tableData.id + 1 }, 
            { title: 'School Name', field: 'schoolName' },
            { title: 'Name', field: 'name' },
            { title: 'Email', field: 'email' },
            { title: 'Phone', field: 'phoneNumber' },
            { title: 'Age', field: 'age' },
            { title: 'Gender', field: 'gender' },
            { title: 'Area Of Interest', field: 'areaOfInterest' },
            { title: 'Nationality', field: 'nationality' },
          ]}
          data={registeredStudents}
          options={{
            detailPanelColumnAlignment: 'right', 
            filtering: false, 
            sorting: true, 
            exportAllData: true, 
            exportMenu: [
              {
                label: 'Export Events Data In CSV', 
                exportFunc: (cols, data) => ExportCsv(cols, data),
              },
            ],
            actionsColumnIndex: -1, 
          }}
          style={{
            boxShadow: '#6a1b9a3d 0px 8px 16px 0px',
            borderRadius: '8px', 
          }}
        />
      )}
    </div>
  );
};

export default ViewRegisterStudentData;
