import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Box, Link, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { storage } from "configs";
import { useUniversities } from 'hooks';
import { useParams } from 'react-router-dom';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    marginBottom: theme.spacing(2),
    '&:before': {
        display: 'none',
    },
    '&:hover': {
        boxShadow: theme.shadows[5],
    },
}));

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // marginBottom: theme.spacing(4),
}));

const ImageContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
}));

const StyledImage = styled('img')(({ theme }) => ({
    borderRadius: '8px',
    height: 100,
    width: 120,
    padding:'10px',
    objectFit: 'cover',
    marginRight: theme.spacing(2),
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
        transform: 'scale(1.05)',
    },
}));

const InfoContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
}));

const Title = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    textAlign: 'left',
    fontWeight: 'bold',
    padding:'8px',
    color: theme.palette.primary.main,
    fontSize: '2rem',
}));

const ProfileVisit = () => {
    const { universityId } = useParams();
    const { universities } = useUniversities();
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const university = universities.find((univ) => univ.id === universityId);

    useEffect(() => {
        if (universityId) {
            fetchDocuments(universityId);
        }
    }, [universityId]);

    const fetchDocuments = async (univId) => {
        try {
            console.log("Fetching documents for University ID:", univId);
            const dirRef = storage.ref(`Users/${univId}/documents`);
            const result = await dirRef.listAll();

            const docPromises = result.items.map(async (itemRef) => {
                try {
                    const url = await itemRef.getDownloadURL();
                    return {
                        fileName: itemRef.name,
                        docURL: url
                    };
                } catch (error) {
                    console.error(`Error fetching URL for file ${itemRef.name}:`, error);
                    return null;
                }
            });

            const docs = await Promise.all(docPromises);
            setDocuments(docs.filter(doc => doc !== null));
        } catch (error) {
            console.error('Error fetching documents:', error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading documents: {error.message}</div>;
    }

    if (!university) {
        return <div>No university found for ID: {universityId}</div>;
    }

    return (
        <div className='h-screen flex flex-col gap-6 items-center p-4'>
            <Container>
                <Title>University Details</Title>
                <ImageContainer>
                    {university.image && (
                        <StyledImage
                            src={university.image}
                            alt="University"
                        />
                    )}
                    <InfoContainer>
                        <Typography variant="h4" component="div" gutterBottom>
                            {university.displayName}
                        </Typography>
                    </InfoContainer>
                </ImageContainer>
            </Container>

            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} sm={10} md={8} lg={6}>
                    <StyledAccordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="email-content"
                            id="email-header"
                        >
                            <Typography variant="h6">Email</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <Link href= {university.email}>
                                    {university.email}
                                </Link>
                               
                            </Typography>
                        </AccordionDetails>
                    </StyledAccordion>

                    <StyledAccordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="phone-content"
                            id="phone-header"
                        >
                            <Typography variant="h6">Phone Number</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                              <Link href={university.phoneNumber}> 
                                    {university.phoneNumber}
                               </Link>
                            </Typography>
                        </AccordionDetails>
                    </StyledAccordion>

                    <StyledAccordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="contact-content"
                            id="contact-header"
                        >
                            <Typography variant="h6">Contact Name</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {university.contactName}
                            </Typography>
                        </AccordionDetails>
                    </StyledAccordion>

                    <StyledAccordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="location-content"
                            id="location-header"
                        >
                            <Typography variant="h6">City</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                    {university.location}
                            </Typography>
                        </AccordionDetails>
                    </StyledAccordion>
                    <StyledAccordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="intro-content"
                            id="introduction-header"
                        >
                            <Typography variant="h6">About Us</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                    {university.intro}
                            </Typography>
                        </AccordionDetails>
                    </StyledAccordion>

                    {university.website && (
                        <StyledAccordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="website-content"
                                id="website-header"
                            >
                                <Typography variant="h6">Website</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <Link href={university.website} target="_blank" rel="noopener noreferrer">
                                        {university.website}
                                    </Link>
                                </Typography>
                            </AccordionDetails>
                        </StyledAccordion>
                    )}

                    {documents.length > 0 && (
                        <StyledAccordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="document-content"
                                id="document-header"
                            >
                                <Typography variant="h6">Brochure</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {documents.map((doc, index) => (
                                        <div key={index}>
                                            <Link href={doc.docURL} download>
                                                {doc.fileName}
                                            </Link>
                                        </div>
                                    ))}
                                </Typography>
                            </AccordionDetails>
                        </StyledAccordion>
                    )}

                </Grid>
                       </Grid>
        </div>
    );
};

export default ProfileVisit;
